.sync-control {
    position: absolute;
    left: -52px;
    bottom: 144px;
    width: 32px;
    height: 32px;
    background-image: url('../../assets/sync.svg');
    background-color: transparent;
    background-size: 80%;
    background-position: center;
    background-repeat: no-repeat;
    outline: none;
    border: none;
}

.sync-control__disabled {
    background-image: url('../../assets/sync_disabled.svg');
}
