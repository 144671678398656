html {
  --mainBg: url('./assets/bg-light.jpg');
  --mainTextColor: rgb(52, 52, 52);
  --tagTextColor: #fff;
  --emptyParticipantBg: rgba(255, 255, 255, .3);
  --placeholderIcon: url('./assets/placeholder-dark.svg');
  --userIcon: url('./assets/user-dark.svg');
  --videoContainerBg: rgba(54, 97, 178, .4);
  --inviteButtonBg: linear-gradient(35deg,rgba(46, 87, 203, .6),rgba(122, 122, 192, 0.7));
  --roomsBg: rgba(0, 32, 85, .5);
}

html[data-theme=dark] {
  --mainBg: url('./assets/bg-dark.jpg');
  --mainTextColor: rgb(170, 210, 240);
  --tagTextColor: rgb(235, 240, 245);
  --emptyParticipantBg: rgba(255, 255, 255, .2);
  --placeholderIcon: url('./assets/placeholder.svg');
  --userIcon: url('./assets/user.svg');
  --videoContainerBg: rgba(0, 123, 255, .3);
  --inviteButtonBg: linear-gradient(35deg,rgba(255, 255, 255, .3),rgba(192, 192, 192, 0.2));
  --roomsBg: rgba(170, 210, 240, .3);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  background: var(--mainBg);
  background-size: auto 100%;
  background-attachment: fixed;
  outline: none!important;
}

@media (min-width: 1700px) {
  body {
    background-size: 120% auto;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}