.se-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 11;
  background: rgba(58, 53, 91, .8);
  backdrop-filter: blur(5px);
}

.se-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  padding: 32px;
  background-color: #0C0025;
  z-index: 10;
  color: #FFFFFF;
  text-align: center;
}

.se-submit {
  margin-top: 15px !important;
}