/* .is-sele .uda-container {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 320px 400px;
  padding: 24px;
} */

.uda-input-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
}

.uda-input-container label {
  color: #fff;
  margin: 0 0 0 4px;
  text-align: left;
}

.uda-input-container select {
  color: #000;
  background-color: #fff;
  outline: none !important;
  border: none !important;
  font-size: 21px;
  line-height: 25px;
  display: flex;
  align-items: center;
  height: 48px;
  padding: 0 8px;
  font-family: Rosario, serif;
  border-radius: 4px;
  appearance: none;
  background-image: url('../../../../../../assets/arrow-down.svg');
  background-position: right 8px center;
  background-repeat: no-repeat;
  background-size: auto 50%;
}

.uda-input {
  border: 1px solid #FFFFFF;
  background-color: #002055 !important;
  color: #FFFFFF !important;
  outline: none !important;
  text-align: left;
  appearance: none !important;
  border-radius: 8px;
  font-size: 21px;
  line-height: 25px;
  display: flex;
  align-items: center;
  height: 48px;
  padding: 0 8px;
  font-family: Rosario, serif;
}

.uda-input::placeholder {
  color: #FFFFFF !important;
  font-style: normal !important;
  opacity: 0.5;
}
.uda-input::-ms-input-placeholder {
  color: #FFFFFF !important;
  font-style: normal !important;
  opacity: 0.5;
}
.uda-input:-ms-input-placeholder {
  color: #FFFFFF !important;
  font-style: normal !important;
  opacity: 0.5;
}
.uda-input::-webkit-input-placeholder {
  color: #FFFFFF !important;
  font-style: normal !important;
  opacity: 0.5;
}

.uda-input:-webkit-autofill,
.uda-input:-webkit-autofill:hover,
.uda-input:-webkit-autofill:focus,
.uda-input:-webkit-autofill:first-line
{
  background-color: transparent !important;
  color: #FFFFFF !important;
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: 0 0 0px 1000px #0C0025 inset;
  font: 21px Rosario, serif !important;
}

.uda-button {
  width: 100% !important;
  margin-top: 20px !important;
  letter-spacing: 1px;
  min-height: 40px;
  transition: background-color .5s;
}

.uda-button[disabled] {
  background-color: #829ab1 !important;
}

.uda-input-error {
  font-family: Rosario, serif;
  color: tomato;
  font-size: 14px;
  font-weight: 300;
  text-align: left;
}

.uda-video-preview-wrapper {
  position: relative;
  width: 100%;
  padding-top: 75%;
  border-radius: 8px;
  overflow: hidden;
  background-color: #3A355B;
}

.uda-video-preview-wrapper video {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.uda-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
