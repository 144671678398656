.notification {
  --notification-text-color: #fff;
  background: rgba(40, 40, 40, .7);
  color: var(--notification-text-color);
  border-radius: 8px;
  padding: 8px 16px;
  padding-right: 22px;
  font-size: 16px;
  line-height: 1.4;
  font-weight: 700;
  max-width: 600px;
  letter-spacing: 1px;
  position: relative;
}

.notification-join {
  font-weight: 600;
  letter-spacing: 0;
  color: var(--notification-text-color);
}

.notification-content::after {
  content: "";
  position: absolute;
  top: -14px;
  right: 80px;
  margin-left: -7px;
  border-width: 7px;
  border-style: solid;
  border-color: transparent transparent rgba(40, 40, 40, .7) transparent;
}

.notification-close {
  border-radius: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 16px;
  width: 16px;
  position: absolute;
  right: 4px;
  top: 4px;
  cursor: pointer;
}

.notification-close svg {
  width: 14px;
  height: 14px;
}

.notification-alert {
  color: rgb(237, 247, 137);
}

.notification-warning {
  color: rgb(235, 80, 112);
}
