.gesture-control {
    position: absolute;
    left: -52px;
    bottom: 96px;
    width: 32px;
    height: 32px;
    background-image: url('../../assets/gesture.svg');
    background-color: transparent;
    background-size: 80%;
    background-position: center;
    background-repeat: no-repeat;
    outline: none;
    border: none;
}

.gesture-control__disabled {
    background-image: url('../../assets/getsture_disabled.svg');
}
