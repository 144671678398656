.login-container {
    width: 100%;
    height: 100vh;
    padding: 16px 16px 0;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(58, 53, 91, .8);
}

.login-card {
    width: 640px;
    margin-top: -100px;
    background-color: rgba(0, 0, 0, .5);
    border-radius: 8px;
    padding: 20px 24px;
}